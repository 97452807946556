import Cookies from "js-cookie";
import Emitter from "../Utils/emitter";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../Context";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import { slideInSlideOut } from "../../Config/motions";
import { v4 as uuid } from "uuid";
import "./Style/style.css";
import {
  AssociatedEmails,
  AssociatedJobs,
  AssociatedPhones,
  AssociatedWorkEmail,
  MiscInfo,
  ProbableEducation,
  SocialMedia,
} from "./SpecificInfoComponents";
import { Spinner } from "../Utils/utils";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import { io } from "socket.io-client";
import MessageDialog from "../PersonalizedMessages/MessageDialog";

const realTimeProfileUrl = `${process.env.REACT_APP_CONFIG_REALTIME_URL}`;
const apiSocketServer = `${process.env.REACT_APP_CONFIG_API_SOCKET_SERVER}`;

const connectUnlockSocket = () => {
  const socket = io(apiSocketServer, {
    path: "/ws/unlock/",
    transports: ["websocket", "polling"],
    auth: { token: `${Cookies.get("user_token")}` },
  });

  socket.on("connect", () => {
    console.log("Connected", socket.id);
  });

  return socket;
};

const SearchSpecificUser = (props) => {
  const [loading, setLoading] = useState(true);
  const [workEmailLoading, setWorkEmailLoading] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const [workEmailDetails, setWorkEmailDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState("");
  const handleClickOpen = async () => {
    setOpen(true);
    setMessage("email");
  };
  const handleWhatsapp = (phone) => {
    setData(phone);
    setOpen(true);
    setMessage("whatsapp");
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { dispatch } = useContext(AppContext);

  const history = useHistory();

  useEffect(() => {
    try {
      if (!userDetails?.image_url?.includes("static")) {
        const image_url = userDetails?.image_url;

        const leadsIndex = props?.index + (props?.currentPage - 1) * 10;
        let updatedLeads = props?.currentLeads;

        if (
          updatedLeads[leadsIndex]?.details_url === props?.data?.details_url
        ) {
          if (updatedLeads[leadsIndex].image_url.includes("static"))
            updatedLeads[leadsIndex].image_url = image_url;

          props?.setCurrentLeads(updatedLeads);
        }
      }
    } catch (e) {
      console.log("e");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails?.image_url, props?.currentLeads]);

  useEffect(() => {
    if (props?.searchId) {
      const socket = connectUnlockSocket();

      socket.on("profile_response", (data) => {
        setLoading(false);
        setUserDetails(data);

        props.onViewProfileOrWorkEmail();

        Emitter.emit("updateCredits", true);
      });

      socket.on("error", (res) => {
        setLoading(false);
        console.warn(res.message);
      });

      socket.emit("unlock_profile", {
        search_id: props?.searchId,
        user_id: `${Cookies.get("user_id")}`,
      });

      return () => {
        socket.disconnect();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.searchId]);

  useEffect(() => {
    if (props?.workEmailId) {
      const socket = connectUnlockSocket();

      socket.on("mail_response", (data) => {
        console.log("got mail response wohoih!");
        setWorkEmailLoading(false);
        setWorkEmailDetails(data);

        props.onViewProfileOrWorkEmail();

        Emitter.emit("updateCredits", true);
      });

      socket.on("error", (res) => {
        console.log("got ErROr");
        setLoading(false);
        setWorkEmailLoading(false);
        console.warn(res.message);
      });

      setWorkEmailLoading(true);

      socket.emit("unlock_work_email", {
        search_id: props?.workEmailId,
        user_id: `${Cookies.get("user_id")}`,
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [props?.workEmailId]);

  const checkExecutionStatus = async () => {
    if (props.data?.details_url === realTimeProfileUrl) {
      console.log("realtimesearchresult", props.realTimeSearchResults);
      let realTimeUserDetail = JSON.parse(props?.realTimeSearchResults);
      setLoading(false);
      setUserDetails(realTimeUserDetail);
      dispatch({
        type: "setSpecificUserInfo",
        payload: realTimeUserDetail,
      });

      Emitter.emit("updateCredits", true);
    } else if (!props.searchId) {
      console.log(
        "%c Search ID is Undefined",
        "background: black; color: yellow"
      );
    }
  };

  async function handleProfile(index, data) {
    const profile = {
      id: uuid(),
      full_name: data?.name,
      location: data?.location,
      job: data?.position,
      details_url: data?.profile_url,
      isRealtime: true,
    };

    history.push({
      pathname: "/UnlockProfile", // BasicSearchResult
      state: { details: profile },
    });
  }

  useEffect(() => {
    if (!userDetails) {
      checkExecutionStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  useEffect(() => {
    (async () => {
      await checkExecutionStatus();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.searchId]);

  // show less function
  const showLess = () => {
    let btn = document.getElementById(
      `proBtn_${props.currentPage}${props.index}`
    );
    if (btn !== null) {
      btn.innerText = "Unlock Profile";
      props.setIsOpen(false);
    } else {
      props.setIsOpen(false);
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "assets/js/app.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [userDetails]);

  return (
    <motion.div {...slideInSlideOut}>
      {loading === false ? (
        !userDetails ? (
          <div>
            <section className="item-section" style={{ textAlign: "center" }}>
              Record Not found
            </section>
          </div>
        ) : (
          <motion.div {...slideInSlideOut} data-cy="profile-data-container">
            {userDetails?.["partial"] && !userDetails?.phones ? (
              <Spinner color={"red"} />
            ) : (
              <>
                <section className="item-section">
                  <div className="phone-child-div">
                    <AssociatedPhones
                      phones={userDetails?.phones}
                      handleWhatsapp={handleWhatsapp}
                    />
                    <AssociatedWorkEmail
                      open={open}
                      handleClickOpen={handleClickOpen}
                      handleClose={handleClose}
                      workEmailInfo={workEmailDetails}
                      workEmailLoading={workEmailLoading}
                      onlyProfile={!props?.workEmailId}
                    />
                  </div>
                </section>
                <section className="item-section">
                  <div className="email-lang-gender-div">
                    <AssociatedEmails emails={userDetails?.emails} />

                    <MiscInfo
                      dob={userDetails?.dob}
                      languages={userDetails?.languages}
                      usernames={userDetails?.usernames}
                    />

                    <MiscInfo gender={userDetails?.gender} />
                  </div>
                </section>
              </>
            )}

            {/* Jobs */}
            {userDetails?.jobs?.length > 0 && (
              <AssociatedJobs jobs={userDetails?.jobs} />
            )}

            {/* Education */}
            {userDetails?.educations?.length > 0 && (
              <ProbableEducation educations={userDetails?.educations} />
            )}

            {/* Social Media */}
            {(userDetails?.probable_urls?.length > 0 ||
              userDetails?.locations?.length > 0) && (
              <SocialMedia
                urls={userDetails?.probable_urls}
                locations={userDetails?.locations}
              />
            )}

            {/* Related Persons */}
            {userDetails && userDetails?.related?.length ? (
              <>
                <section>
                  <div
                    className="user-widget-box text-center p-4 my-3"
                    style={{ position: "relative" }}
                  >
                    <h4 className="text-left"> Probable People Associated </h4>{" "}
                    <Swiper
                      // style={{ border: '1px solid crimson' }}
                      breakpoints={{
                        768: {
                          slidesPerView: 2,
                        },
                        991: {
                          slidesPerView: 3,
                        },
                      }}
                      spaceBetween={50}
                      slidesPerView={1}
                      onSlideChange={() => console.log("slide change")}
                      onSwiper={(swiper) => console.log(swiper)}
                      loop={true}
                      pagination={{
                        clickable: true,
                      }}
                      navigation={true}
                      modules={[Pagination, Navigation]}
                    >
                      {userDetails?.related?.length
                        ? userDetails.related.map((profile, index) => (
                            <SwiperSlide key={index} className="my-3">
                              <div
                                className="d-flex flex-column align-items-center"
                                style={{ marginBottom: "1.5rem", height: "250px" }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "1rem",
                                  }}
                                >
                                  <a
                                    href={profile.profile_url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      className=""
                                      src="/assets/images/user-author-pic.png"
                                      alt=""
                                    />
                                  </a>{" "}
                                </div>{" "}
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                  className="d-block mt-3"
                                >
                                  {" "}
                                  {profile.name}{" "}
                                </p>{" "}
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    justifyContent: "space-evenly",
                                    marginRight: "1.2rem",
                                    marginLeft: "1.2rem",
                                    // minHeight: "100px",
                                    // border: '1px solid crimson'
                                  }}
                                >
                                  <p
                                    style={{
                                      wordBreak: "break-word",
                                      width: "15rem",
                                      textAlign: "center",
                                    }}
                                    className="d-block mt-1"
                                  >
                                    {" "}
                                    {profile.summary} at {profile.location}{" "}
                                  </p>{" "}
                                </div>
                                <div
                                  // style={{ position: "fixed", bottom: "0" }}
                                  className="search-view-btn mt-auto mb-2"
                                >
                                  <button
                                    className="btn button"
                                    onClick={() =>
                                      handleProfile(props.index, profile)
                                    }
                                    style={
                                      profile.meta_data?.[
                                        "unlocked_profile"
                                      ] && {
                                        backgroundColor: "#FF6852",
                                        color: "white",
                                        fontSize: "14px",
                                      }
                                    }
                                  >
                                    {profile.meta_data?.["unlocked_profile"]
                                      ? "View Profile"
                                      : "Unlock Profile"}
                                  </button>
                                </div>
                              </div>
                            </SwiperSlide>
                          ))
                        : null}{" "}
                    </Swiper>
                  </div>
                </section>
                <section className=" text-center">
                  {props.isSavedLead ? (
                    <a
                      className="btn button collapse show"
                      data-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls={`#collapseExample_${props.id}`}
                      href={`#collapseExample_${props.id}`}
                      data-target={`#collapseExample_${props.id}`}
                      onClick={() => {
                        props.setIsOpen(false);
                      }}
                      style={{ width: "fit-content", margin: "0 auto" }}
                    >
                      Show Less
                    </a>
                  ) : (
                    <a
                      className="btn button"
                      data-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                      href={`#collapseExample_${props.currentPage}${props.index}`}
                      data-target={`#collapseExample_${props.currentPage}${props.index}`}
                      onClick={showLess}
                    >
                      Show Less
                    </a>
                  )}
                </section>
              </>
            ) : (
              <section className=" text-center ">
                <a
                  className="btn button"
                  data-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  href={
                    props.isSavedLead
                      ? `#collapseExample_${props.id}`
                      : `#collapseExample_${props.currentPage}${props.index}`
                  }
                  data-target={
                    props.isSavedLead
                      ? `#collapseExample_${props.id}`
                      : `#collapseExample_${props.currentPage}${props.index}`
                  }
                  onClick={showLess}
                >
                  Show Less
                </a>
              </section>
            )}
          </motion.div>
        )
      ) : (
        <div className="d-flex justify-content-center">
          <Spinner color="darkgrey" />
        </div>
      )}
      <MessageDialog
        open={open}
        data={data}
        onClose={handleClose}
        title={message}
      />
    </motion.div>
  );
};

export default SearchSpecificUser;
